.wrapper {
  display: none;
  margin-top: var(--spacing-6);
  padding: var(--spacing-6);
  &,
  & > div {
    background: var(--color-primary-50);
  }
  @media (--tablet) {
    display: block;
  }
}

.faqMobile {
  @media (--mobile) {
    display: block;
  }
}

.title {
  margin: 0 0 var(--spacing-3);
  font-weight: 700;
}

.accordionTitle {
  margin: 0;

  &:last-child {
    border-bottom: 0;
  }
}

.content {
  & a {
    color: var(--color-primary-500);
  }
  & > p {
    margin: 0;
    font-weight: 400;
  }
}
